import React from "react";
import classes from "./AboutAKGEC.module.css";
import classesDark from "./AboutAKGECDark.module.css";
import AdminBlock from "../../../assets/images/admin_akgec.jpg";
const AboutAKGEC = (props) => {
  let styles = classes;
  if (props.theme) {
    styles = classes;
  } else {
    styles = classesDark;
  }
  const akgecURL= "https://www.akgec.ac.in/";
  return (
    <div className={styles.AboutAKGEC}>
      <div className={styles.Container}>
        <h2 className={styles.AKGECHeader}>
          About <span>AKGEC</span>
        </h2>
        <div className={styles.AKGECContent}>
          <img src={AdminBlock} alt="AKGEC" />
          <div className={styles.AKGECBody}>
            <p>
              Ajay Kumar Garg Engineering College (AKGEC) is a private
              engineering college located in Ghaziabad, Uttar Pradesh, India. It
              was established in 1998 and is affiliated to Dr APJ Abdul Kalam
              Technical University (AKTU). The college offers undergraduate
              (B.Tech) programs in engineering, as well as a Master of Computer
              Applications (MCA) program.</p>
              <p> AKGEC has been consistently
              maintaining excellent academic results and placements. The college
              has been awarded the Academic Excellence Award for the Best
              Engineering College in UPTU by the Governor of Uttar Pradesh for
              two consecutive years.
            </p>
            <a href={akgecURL} target="_blank" rel="noopener noreferrer">
            SEE MORE
          </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAKGEC;
