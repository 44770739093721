import { configureStore } from "@reduxjs/toolkit";
import tokenErrorSlice from "./Slices/tokenErrorSlice";
import userSlice from "./Slices/userSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    tokenError: tokenErrorSlice,
  },
});
