import React from "react";

// css import
import classes from "./ShowcaseHome.module.css";
import classesDark from "./ShowcaseHomeDark.module.css";

//Material UI
// import Box from "@mui/material/Box";
// import Fab from "@mui/material/Fab";
// import NavigationIcon from "@mui/icons-material/Navigation";

// image import
import showcaseImage from "../../../assets/images/showcaseImage.svg";

//componentImport
import Button from "../../UI/Button/Button";

const ShowcaseHome = (props) => {
  let styles = classes;
  if (props.theme) {
    styles = classes;
  } else {
    styles = classesDark;
  }
  return (
    <div className={styles.container}>
      <div className={styles.ShowcaseHome}>
        <div className={styles.ShowcaseBody}>
          <h1>
            Developer Student Groups <span>AKGEC</span>
          </h1>
          {/* <Box
            sx={{
              "& > :not(style)": { m: 0 },
              position: "absolute",
              right: "10rem",
              top: "8rem",
            }}
          >
            <Fab variant="extended">
              <NavigationIcon sx={{ mr: 1 }} />
              Navigate
            </Fab>
          </Box> */}
          <p>
            Google Developer Student Groups are university based community groups
            for students interested in Google developer technologies.By joining
            a GDG On Campus, students grow their knowledge in a peer-to-peer learning
            environment and build solutions for local businesses and their
            community.
          </p>
          <Button
            href="https://developers.google.com/community/dsc"
            icon="info"
            theme={props.theme}
          >
            &nbsp;LEARN MORE
          </Button>
        </div>
        <img src={showcaseImage} alt="DSC" />
      </div>
    </div>
  );
};

export default ShowcaseHome;

// import { CSSTransition } from "react-transition-group";
// <CSSTransition
//   in={true}
//   timeout={{
//     appear: 400,
//   }}
//   appear={true}
//   classNames={{ ...classes }}
// >
// </CSSTransition>
