// import person1 from "../../assets/images/person.jpg";

//2024 passouts
import Yash from "../../../assets/images/team/Yash.jfif";
import Priyanshi from "../../../assets/images/team/Priyanshi.jpeg";
import Prerna from "../../../assets/images/team/Prerna.jpeg";
import Udit from "../../../assets/images/team/Udit.png";
import Arpan from "../../../assets/images/team/Arpan.png";
import Sankalp from "../../../assets/images/team/Sankalp.png";
import Abhinav from "../../../assets/images/team/Abhinav.png";
import Anurag from "../../../assets/images/team/Anurag.png";
import Garima from "../../../assets/images/team/Garima.jpg";
import Aryan from "../../../assets/images/team/Aryan.png";
import Vandya from "../../../assets/images/team/Vandya.png";
import Hir from "../../../assets/images/team/Hir.png";
import Sajal from "../../../assets/images/team/Sajal.png";
import Anushka from "../../../assets/images/team/anushka.jpeg";




//2023 passouts
import Aagiya from "../../../assets/images/team/Aagiya.jpg";
import Aashima from "../../../assets/images/team/Aashima.jpg";
import Adnan from "../../../assets/images/team/Adnan.jpeg";
import Anuj from "../../../assets/images/team/Anuj.jpeg";
import Anushree from "../../../assets/images/team/Anushree.jpeg";
import Apra from "../../../assets/images/team/Apra.jpeg";
import Arpit from "../../../assets/images/team/Arpit.jpg";
import ManishDixit from "../../../assets/images/team/ManishDixit.jpg";
import ManishKumar from "../../../assets/images/team/ManishKumar.jpeg";
import Manya from "../../../assets/images/team/Manya.jpg";
import Preeti from "../../../assets/images/team/Preeti.jpeg";
import Shivani from "../../../assets/images/team/Shivani.jpg";
import Shreyanshi from "../../../assets/images/team/Shreyanshi.jpg";
import Shubhi from "../../../assets/images/team/Shubhi.jpg";
import Sudheer from "../../../assets/images/team/Sudheer.jpg";
import Tashi from "../../../assets/images/team/Tashi.jpg";
import Vibhuti from "../../../assets/images/team/Vibhuti.jpg";

//2022 passouts
import Anshika from "../../../assets/images/team/Anshika.jpg";
import Apoorv from "../../../assets/images/team/Apoorv.jpg";
import AyushiB from "../../../assets/images/team/AyushiB.jpg";
import Himaniii from "../../../assets/images/team/Himaniii.jpeg";
import Hitesh from "../../../assets/images/team/Hitesh.jpg";
import Krishna from "../../../assets/images/team/Krishna.jpeg";
import NihalMB from "../../../assets/images/team/Nihal.jpg";
import Pranjal from "../../../assets/images/team/Pranjul.jpg";
import PranjulItondia from "../../../assets/images/team/Pranjulitondia.jpg";
import Rishabh from "../../../assets/images/team/Rishabh.jpeg";
import Shatakshi from "../../../assets/images/team/Shatakshi.jpg";
import ShivamB from "../../../assets/images/team/ShivamB.jpg";
import Shubh from "../../../assets/images/team/Shubh.jpg";
import Tarun from "../../../assets/images/team/TarunR.jpg";
import VisheshD from "../../../assets/images/team/VisheshD.jpeg";
import VisheshK from "../../../assets/images/team/VisheshK.png";

//2021 passouts
import MansiGoel from "../../../assets/images/Alumni/MansiG.jpg";

//2020 passouts
import RohanAsthana from "../../../assets/images/Alumni/RohanAsthana.jpg";
import AmritanshKumar from "../../../assets/images/Alumni/AmritanshKumar.jpg";
import AnkitSharma from "../../../assets/images/Alumni/Ankit.jpeg";
import ArpitKansal from "../../../assets/images/Alumni/ArpitKansal.jpg";
import BhavyaTripathi from "../../../assets/images/Alumni/BhavyaTripathi.jpg";
import EktaSrivastava from "../../../assets/images/Alumni/EktaSrivastava.jpg";
import KushagraTripathi from "../../../assets/images/Alumni/KushagraTripathi.jpg";
import MohammadAkbar from "../../../assets/images/Alumni/MohammadAkbar.jpeg";
import MuditMehrotra from "../../../assets/images/Alumni/MuditMehrotra.jpeg";
import NiteshGupta from "../../../assets/images/Alumni/NiteshGupta.jpg";
import ShaktiJaiswal from "../../../assets/images/Alumni/ShaktiJaiswal.jpg";
import ShresthJauhari from "../../../assets/images/Alumni/ShresthJauhari.jpg";

export const AlumniData = [
  {
    id: 1,
    domain: 5,
    year: 2021,
    cardName: "Mansi Goel",
    cardDesignation: "Lead (2020)",
    image: MansiGoel,
    links: {
      linkedin: "https://www.linkedin.com/in/mansigoel222175191/",
    },
  },
  {
    id: 2,
    domain: 5,
    year: 2020,
    cardName: "Rohan Asthana",
    cardDesignation: "Lead (2019)",
    image: RohanAsthana,
    links: {
      linkedin: "https://www.linkedin.com/in/rohan-asthana/",
    },
  },
  {
    id: 3,
    domain: 5,
    year: 2020,
    cardName: "Amritansh Kumar",
    image: AmritanshKumar,
    links: {
      linkedin: "https://www.linkedin.com/in/amritansh-kumar-613463149/",
    },
  },
  {
    id: 4,
    domain: 5,
    year: 2020,
    cardName: "Ankit Sharma",
    image: AnkitSharma,
    links: {
      linkedin: "https://www.linkedin.com/in/ankiitshrma/",
    },
  },
  {
    id: 5,
    domain: 5,
    year: 2020,
    cardName: "Arpit Kansal",
    image: ArpitKansal,
    links: {
      linkedin: "https://www.linkedin.com/in/arpit-kansal-379607139/",
    },
  },
  {
    id: 6,
    domain: 5,
    year: 2020,
    cardName: "Bhavya Tripathi",
    image: BhavyaTripathi,
    links: {
      linkedin: "https://www.linkedin.com/in/bhavya-tripathi/",
    },
  },
  {
    id: 7,
    domain: 5,
    year: 2020,
    cardName: "Ekta Srivastava",
    image: EktaSrivastava,
    links: {
      linkedin: "https://www.linkedin.com/in/ekta-srivastava-458515165/",
    },
  },
  {
    id: 8,
    domain: 5,
    year: 2020,
    cardName: "Kushagra Tripathi",
    image: KushagraTripathi,
    links: {
      linkedin: "https://www.linkedin.com/in/kushagra-tripathi-78b0a9158/",
    },
  },
  {
    id: 9,
    domain: 5,
    year: 2020,
    cardName: "Mohammad Akbar",
    image: MohammadAkbar,
    links: {
      linkedin: "https://in.linkedin.com/in/mohd-akbar-67a338173/",
    },
  },
  {
    id: 10,
    domain: 5,
    year: 2020,
    cardName: "Mudit Mehrotra",
    image: MuditMehrotra,
    links: {
      linkedin: " https://www.linkedin.com/mwlite/in/mudit-mehrotra-679974145",
    },
  },
  {
    id: 11,
    domain: 5,
    year: 2020,
    cardName: "Nitesh Gupta",
    image: NiteshGupta,
    links: {
      linkedin: "https://www.linkedin.com/in/nitesh-gupta-39b505144/",
    },
  },
  {
    id: 12,
    domain: 5,
    year: 2020,
    cardName: "Shakti Jaiswal",
    image: ShaktiJaiswal,
    links: {
      linkedin: "https://www.linkedin.com/in/shakti-jaiswal-b81945178/",
    },
  },
  {
    id: 13,
    domain: 5,
    year: 2020,
    cardName: "Shresth Jauhari",
    image: ShresthJauhari,
    links: {
      linkedin: "https://www.linkedin.com/in/shresth-jauhari-830616a7/",
    },
  },
  {
    id: 14,
    year: 2022,
    domain: 3,
    cardName: "Anshika Bajpai",
    cardDesignation: "App Development",
    image: Anshika,
    links: {
      github: "www.github.com/Anshika10th",
      linkedin: "https://www.linkedin.com/in/anshika-bajpai-73b5761ab",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 15,
    year: 2022,
    domain: 2,
    cardName: "Apoorv Jain",
    cardDesignation: "Machine Learning",
    image: Apoorv,
    links: {
      github: "https://github.com/apoorv-jain",
      linkedin: "https://www.linkedin.com/in/apoorv-jain-53b53b199/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 16,
    year: 2022,
    domain: 4,
    cardName: "Ayushi Bansal",
    cardDesignation: "Managerial",
    image: AyushiB,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/ayushi-bansal-4291601b6/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 17,
    year: 2022,
    domain: 1,
    cardName: "Himani Chauhan",
    cardDesignation: "Web Development",
    image: Himaniii,
    links: {
      github: "https://github.com/himani100700",
      linkedin: "https://www.linkedin.com/in/himani-chauhan-100700/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 18,
    year: 2022,
    domain: 1,
    cardName: "Hitesh Tripathi",
    cardDesignation: "Web Development",
    image: Hitesh,
    links: {
      github: "https://github.com/h1teshtr1path1",
      linkedin: "https://www.linkedin.com/in/hitesh-tripathi-a7b1a6193/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 19,
    year: 2022,
    domain: 1,
    cardName: "Krishna Agarwal",
    cardDesignation: "Web Development",
    image: Krishna,
    links: {
      github: "https://github.com/krxxnna",
      linkedin: "https://www.linkedin.com/in/krishna-a-34427b131/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 20,
    year: 2022,
    domain: 2,
    cardName: "Nihal MB Choudhary",
    cardDesignation: "Machine Learning",
    image: NihalMB,
    links: {
      github: "https://github.com/nihalmb",
      linkedin: "https://www.linkedin.com/in/nihal-mb-choudhary-b261061b6/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 21,
    year: 2022,
    domain: 4,
    cardName: "Pranjal Maurya",
    cardDesignation: "Managerial",
    image: Pranjal,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/pranjal-maurya-a249b1173",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 22,
    year: 2022,
    domain: 2,
    cardName: "Pranjul Itondia",
    cardDesignation: "Machine Learning",
    image: PranjulItondia,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/pranjul-itondia-70912317b/",
      instagram: "/",
      behance: "/",
    },
  },

  {
    id: 23,
    year: 2022,
    domain: 3,
    cardName: "Rishabh Singh",
    cardDesignation: "App Development",
    image: Rishabh,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/workwithrishabh/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 24,
    year: 2022,
    domain: 4,
    cardName: "Shatakshi Upadhyay",
    cardDesignation: "Managerial",
    image: Shatakshi,
    links: {
      github: "https://github.com/Shatakshi01",
      linkedin: "https://www.linkedin.com/in/shatakshi-upadhyay-i",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 25,
    year: 2022,
    domain: 1,
    cardName: "Shivam Bisht",
    cardDesignation: "Web Development",
    image: ShivamB,
    links: {
      github: "https://github.com/shivam-S-bisht",
      linkedin: "https://www.linkedin.com/in/shivambisht2001/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 26,
    year: 2022,
    domain: 4,
    cardName: "Shubh Shah",
    cardDesignation: "Managerial",
    image: Shubh,
    links: {
      github: "https://github.com/dsc-shubh",
      linkedin: "https://www.linkedin.com/in/shubh-shah-5b4182167/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 27,
    year: 2022,
    domain: 2,
    cardName: "Tarun Raghav",
    cardDesignation: "Machine Learning",
    image: Tarun,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/imtarunraghav/",
      instagram: "/",
      behance: "/",
    },
  },

  {
    id: 28,
    year: 2022,
    domain: 1,
    cardName: "Vishesh Dhawan",
    cardDesignation: "Web Development",
    image: VisheshD,
    links: {
      github: "https://github.com/visheshdvn",
      linkedin: "https://www.linkedin.com/in/visheshdvn/",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 29,
    year: 2022,
    domain: 2,
    cardName: "Vishesh Kushwaha",
    cardDesignation: "Machine Learning",
    image: VisheshK,
    links: {
      github: "https://github.com/vishesh342",
      linkedin: "http://linkedin.com/in/vishesh-342",
      instagram: "/",
      behance: "/",
    },
  },
  {
    id: 45,
    year: 2023,
    domain: 2,
    cardName: "Tashi Gautam",
    cardDesignation: "Lead (2021)",
    image: Tashi,
    links: {
      github: "https://github.com/tashi2408",
      linkedin: "https://www.linkedin.com/in/tashi-gautam-77b95b1b6/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 30,
    year: 2023,
    domain: 1,
    cardName: "Aagiya Singh",
    cardDesignation: "Web Development",
    image: Aagiya,
    links: {
      github: "https://github.com/aagiyasingh",
      linkedin: "https://www.linkedin.com/in/aagiya-singh-99b81a1ab",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 31,
    year: 2023,
    domain: 1,
    cardName: "Aashima Saxena",
    cardDesignation: "Web Development",
    image: Aashima,
    links: {
      github: "https://github.com/aashimasaxena91",
      linkedin: "https://www.linkedin.com/in/aashima-saxena-976554159",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 32,
    year: 2023,
    domain: 3,
    cardName: "Adnan Ali",
    cardDesignation: "App Development",
    image: Adnan,
    links: {
      github: "https://github.com/Adnanali777",
      linkedin: "https://www.linkedin.com/in/adnan-ali-4328751b7",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 33,
    year: 2023,
    domain: 1,
    cardName: "Anuj Negi",
    cardDesignation: "Web Development",
    image: Anuj,
    links: {
      github: "https://github.com/anujnegi5",
      linkedin: "https://www.linkedin.com/in/anuj-negi-6bb2bb1a5/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/anujnegi_",
    },
  },

  {
    id: 34,
    year: 2023,
    domain: 1,
    cardName: "Anushree Gupta",
    cardDesignation: "Web Development",
    image: Anushree,
    links: {
      github: "https://github.com/guptaaanushree",
      linkedin: "https://www.linkedin.com/in/guptaaanushree",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 35,
    year: 2023,
    domain: 3,
    cardName: "Apra Jain",
    cardDesignation: "App Development",
    image: Apra,
    links: {
      github: "https://github.com/aprajain",
      linkedin: "https://www.linkedin.com/in/apra-jain",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/ApraJain",
    },
  },

  {
    id: 36,
    year: 2023,
    domain: 3,
    cardName: "Arpit Chauhan",
    cardDesignation: "App Development",
    image: Arpit,
    links: {
      github: "https://github.com/Arpit-Chauhan ",
      linkedin: "https://www.linkedin.com/in/arpit-chauhan-15b853176/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 37,
    year: 2023,
    domain: 1,
    cardName: "Manish Dixit",
    cardDesignation: "Web Development",
    image: ManishDixit,
    links: {
      github: "https://github.com/manishdxt",
      linkedin: "https://www.linkedin.com/in/manish-dixit-11b6b0196",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/thatmanishh",
    },
  },

  {
    id: 38,
    year: 2023,
    domain: 3,
    cardName: "Manish Kumar",
    cardDesignation: "App Development",
    image: ManishKumar,
    links: {
      github: "https://github.com/mannnish",
      linkedin: "https://www.linkedin.com/in/manish-kumar-21a8b4183/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 39,
    year: 2023,
    domain: 2,
    cardName: "Manya Chaudhary",
    cardDesignation: "Machine Learning",
    image: Manya,
    links: {
      github: "https://github.com/ManyaChdry",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 40,
    year: 2023,
    domain: 1,
    cardName: "Preeti Jaiswal",
    cardDesignation: "Web Development",
    image: Preeti,
    links: {
      github: "https://github.com/jassu2000",
      linkedin: "https://www.linkedin.com/in/preeti-jaiswal-1899621a4/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 41,
    year: 2023,
    domain: 4,
    cardName: "Shivani Khare",
    cardDesignation: "Managerial",
    image: Shivani,
    links: {
      github: "https://github.com/shivanikhare791",
      linkedin: "https://www.linkedin.com/in/shivani-khare-792001/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 42,
    year: 2023,
    domain: 2,
    cardName: "Shreyanshi Singh",
    cardDesignation: "Machine Learning",
    image: Shreyanshi,
    links: {
      github: "github.com/shreyanshi28",
      linkedin: "https://www.linkedin.com/in/shreyanshi-singh-94009a190/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 43,
    year: 2023,
    domain: 4,
    cardName: "Shubhangi Shukla",
    cardDesignation: "Managerial",
    image: Shubhi,
    links: {
      github: "https://github.com/ShubhangiShukla23",
      linkedin: "https://www.linkedin.com/mwlite/in/shubhangi-shukla-30a1181b8",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 44,
    year: 2023,
    domain: 1,
    cardName: "Sudheer Singh",
    cardDesignation: "Web Development",
    image: Sudheer,
    links: {
      github: "https://github.com/ss0710",
      linkedin: "https://www.linkedin.com/in/sudheer-singh-6132951a2",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  
  {
    id: 46,
    year: 2023,
    domain: 4,
    cardName: "Vibhuti Agarwal",
    cardDesignation: "Managerial",
    image: Vibhuti,
    links: {
      github: "https://github.com/Vibhut28",
      linkedin: "http://www.linkedin.com/in/vibhutiagrawal28",
      instagram: "/",
      behance: "/",
      twitter:"/",
      
    },
  },
  {
    id: 47,
    year: 2024,
    domain: 1,
    cardName: "Abhinav Verma",
    cardDesignation: "Web Development",
    image: Abhinav,
    links: {
      github: "https://github.com/abhinavv9",
      linkedin: "https://www.linkedin.com/in/abhinav-verma-0779001aa",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/0xsudoboink",
    },
  },
  {
    id: 48,
    year: 2024,
    domain: 5,
    cardName: "Anurag Singh Patel",
    cardDesignation: "UI/UX Designing",
    image: Anurag,
    links: {
      github: "https://github.com/anuragsingh1409",
      linkedin: "https://www.linkedin.com/in/anurag-singh-46534b1aa",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 49,
    year: 2024,
    domain: 4,
    cardName: "Anushka Mishra",
    cardDesignation: "Managerial",
    image: Anushka,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/anushka-mishra-919279224/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 50,
    year: 2024,
    domain: 1,
    cardName: "Arpan Gupta",
    cardDesignation: "Web Development",
    image: Arpan,
    links: {
      github: "https://github.com/arpangupta1003",
      linkedin: "https://www.linkedin.com/in/arpan-gupta-918822202/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 51,
    year: 2024,
    domain: 1,
    cardName: "Aryan Singhal",
    cardDesignation: "Web Development",
    image: Aryan,
    links: {
      github: "https://www.github.com/AryanSinghal1",
      linkedin: "https://www.linkedin.com/in/aryan-singhal-477b32213",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 52,
    year: 2024,
    domain: 2,
    cardName: "Garima Saroj",
    cardDesignation: "Lead (2022)",
    image: Garima,
    links: {
      github: "https://github.com/AndroGari",
      linkedin: "https://www.linkedin.com/in/garimasaroj",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/Andro_Gari",
    },
  },
  {
    id: 53,
    year: 2024,
    domain: 5,
    cardName: "Hirnaymay Bhaskar",
    cardDesignation: "UI/UX Designing",
    image: Hir,
    links: {
      github: "https://github.com/octopols",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 54,
    year: 2024,
    domain: 3,
    cardName: "Prerna Gupta",
    cardDesignation: "App Development",
    image: Prerna,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/prerna-gupta-608b5b221",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/Prerna2625",
    },
  },
  {
    id: 55,
    year: 2024,
    domain: 2,
    cardName: "Priyanshi Kushwaha",
    cardDesignation: "Machine Learning",
    image: Priyanshi,
    links: {
      github: "https://github.com/kpriyanshi02",
      linkedin: "https://www.linkedin.com/in/priyanshi-kushwaha-59a659219/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 56,
    year: 2024,
    domain: 3,
    cardName: "Sankalp Srivastava",
    cardDesignation: "App Development",
    image: Sankalp,
    links: {
      github: "https://github.com/sank4lp55",
      linkedin: "https://www.linkedin.com/in/sankalp-srivastava-03a73b22b/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/Sankalp25591771",
    },
  },
  {
    id: 57,
    year: 2024,
    domain: 4,
    cardName: "Sajal Sangal",
    cardDesignation: "Managerial",
    image: Sajal,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/sajal-sangal-6221a61b7/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 58,
    year: 2024,
    domain: 3,
    cardName: "Udit Kansal",
    cardDesignation: "App Development",
    image: Udit,
    links: {
      github: "https://github.com/25kudit/",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 59,
    year: 2024,
    domain: 4,
    cardName: "Vandya Sharma",
    cardDesignation: "Managerial",
    image: Vandya,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/vandya-sharma-b99a8b200",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 60,
    year: 2024,
    domain: 1,
    cardName: "Yash Khare",
    cardDesignation: "Web Development",
    image: Yash,
    links: {
      github: "https://github.com/khareyash05",
      linkedin: "https://www.linkedin.com/in/yash-khare-0978821aa/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/YashKha57954753",
    },
  },
];
