import React from "react";
import { Link } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./Button.css";

const Button = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <span>
      <Link onClick={scrollTop} className="btn">
        <ArrowUpwardIcon fontSize="large" color="success" />
      </Link>
    </span>
  );
};

export default Button;
