// import images
import gcp from "../../assets/images/Events/gcp.jpg";
import weekOfLearning from "../../assets/images/Events/weekoflearning.jpg";
import hackfest1 from "../../assets/images/Events/hackfest.jpg";
import hackfest2 from "../../assets/images/Events/hackfest2.jpg";
import hackfest3 from "../../assets/images/Events/hackfest3.jpg";
import androidStudyJams from "../../assets/images/Events/ASJ.jpg";
import mlEvent from "../../assets/images/Events/mlevent.jpg";
import webEvent from "../../assets/images/Events/webevent.png";
import jetpack from "../../assets/images/Events/jetpack.png"
import mlStudy from "../../assets/images/Events/mlstudy.jfif";
import gccp from "../../assets/images/Events/gccp.png"
import mljams from "../../assets/images/Events/mljams.png"
import flutterForward from "../../assets/images/Events/flutterForward.jpeg"
import weekOfLearning2 from "../../assets/images/Events/wol2.png"
import infosession1 from "../../assets/images/Events/info session.jpg"
import infosession2 from "../../assets/images/Events/info session 2.webp"
import gcp30 from "../../assets/images/Events/Google Cloud.webp"
import solutionchallenge from "../../assets/images/Events/solutionchallenge.webp"
import gdscwow from "../../assets/images/Events/WOW.jpg"
import flutterfestival from "../../assets/images/Events/FlutterFestival.webp"
import exploreml from "../../assets/images/Events/Ml beginner.webp"
import webfirebase from "../../assets/images/Events/web+firebase.webp"
import winterlude from "../../assets/images/Events/winterlude.webp"
import wow2 from "../../assets/images/Events/wow2.webp"
import githubtut from "../../assets/images/Events/github.png"
import introtoweb from "../../assets/images/Events/intro to web.jpeg"
import gcloud from "../../assets/images/Events/gccp3.webp"
import flutterinfo from "../../assets/images/Events/Flutterinfo.webp"
import flutter from "../../assets/images/Events/Flutter.png"
import webWeave from "../../assets/images/Events/web_weave.webp"
import studyJams from "../../assets/images/Events/study_jams.webp"
import tensoFlow from "../../assets/images/Events/tensorflow.webp"
import solutionChallenge2 from "../../assets/images/Events/solutionchallenge2.webp"
import mlstudyJams from "../../assets/images/Events/mlstudyjams.webp"
import flutterFusion from "../../assets/images/Events/fluuterfusion.webp"


export const EventsData = [

  {
    id: 37,
    cardTitle: "Flutter Fusion",
    cardSubtitle: "22 Feb 2024 - 24 Feb 2024",
    cardText:"We invite you to join Day 1 of our Android Bootcamp as part of Discover, Design, Develop campaign (in collaboration with GDSCs across India and Africa)",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-mata-sundri-college-for-women-delhi-presents-flutter-fusion/cohost-ajay-kumar-garg-engineering-college-ghaziabad",
    eventImg: flutterFusion,
  },{
    id: 36,
    cardTitle: "ML Study Jams",
    cardSubtitle: "24 Jan 2024",
    cardText:"From data preparation to model deployment, we'll cover everything you need to know to get started in this exciting field. Join us for an exclusive session where you'll learn about the different aspects of machine learning.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-abes-engineering-college-ghaziabad-presents-ml-study-jams-3/cohost-ajay-kumar-garg-engineering-college-ghaziabad",
    eventImg: mlstudyJams,
  },{
    id: 35,
    cardTitle: "Getting started with Solution Challenge",
    cardSubtitle: "03 Dec 2023",
    cardText:"We are hosting an info session for solution challenge discussing solution challenge rules, timeline, judging criteria, and team building.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-arya-college-of-engineering-it-jaipur-presents-getting-started-with-solution-challenge-1/cohost-ajay-kumar-garg-engineering-college-ghaziabad",
    eventImg: solutionChallenge2,
  },{
    id: 34,
    cardTitle: "Tensorflow:PowerUp",
    cardSubtitle: "4 Nov 2023",
    cardText:"This event is your gateway to discovering the boundless potential of TensorFlow across various domains. From data-driven insights to cutting-edge Android applications and cloud-based solutions, TensorFlow empowers every facet of technology.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-tensorflow-powerup/",
    eventImg: tensoFlow,
  },{
    id: 33,
    cardTitle: "Study Session-Google Cloud Study jams",
    cardSubtitle: "15 Oct 2023",
    cardText:"Google Cloud Study Jams is a month long program to promote students to learn about Google Cloud. This study session will provide details and steps about the same.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-study-session-google-cloud-study-jams/",
    eventImg: studyJams,
  },
  {
    id: 32,
    cardTitle: "Info Session-Google Cloud Study jams",
    cardSubtitle: "06 Oct 2023",
    cardText:"Google Cloud Study Jams is a month long program to promote students to learn about Google Cloud. This Info session will provide basic details and steps about the same.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-info-session-google-cloud-study-jams/",
    eventImg: studyJams,
  },{
    id: 31,
    cardTitle: "WebWeave",
    cardSubtitle: "17 Sep 2023",
    cardText:"WebWeave is an event focusing on development using Google Technologies.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-webweave/",
    eventImg: webWeave,
  },{
    id: 30,
    cardTitle: "Week Of Learning",
    cardSubtitle: "13 May 2023 - 14 May 2023",
    cardText:"Calling all tech enthusiasts! 'Week of Learning' from 13th May to 22nd May will equip tech enthusiasts with the latest and in-demand skills in the tech industry, including App Development, Web Development, Machine Learning, DevOps, UI/UX designing and startup.",
    cardHref:
    "",
    eventImg: weekOfLearning2,
  },
  {
    id: 29,
    cardTitle: "ML Study Jams",
    cardSubtitle: "13 Apr 2023 - 14 Apr 2023",
    cardText:"Experience the potential of machine learning through our ML Study Jams! Our comprehensive sessions cover all the fundamentals, from data preparation to model deployment, providing a solid foundation in this exciting field. ",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-ml-study-jams-day-1/",
    eventImg: mljams,
  },
  {
    id: 28,
    cardTitle: "GDSC WoW",
    cardSubtitle: "1 Apr 2023",
    cardText:"The first and craziest #GDSCWoW Offline will take place in April, and we are thrilled to announce it. A collaborative event between 70+ GDSCs from India aims to unite communities, students, and developers under one roof.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-gdsc-wow-delhi-ncr/",
    eventImg: wow2,
  },
  {
    id: 27,
    cardTitle: "Flutter Forward Extended",
    cardSubtitle: "10 Mar 2023 - 11 Mar 2023",
    cardText:"Google Developer Student Clubs, AKGEC is thrilled to announce the highly anticipated second day of Flutter Forward Extended. During this event, participants will have the opportunity to learn about Flutter.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-flutter-forward-extended-day-2/",
    eventImg: flutterForward,
  },

  {
    id: 26,
    cardTitle: "Flutter Info Session",
    cardSubtitle: "6 Mar 2023",
    cardText:"We are thrilled to announce an extremely informative session in collaboration with GDSCs, 'Flutter Forward Extended'. Here you will learn Flutter to build natively compiled, multi-platform applications from a single codebase.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-flutter-forward-extended/",
    eventImg: flutterinfo,
  },
  {
    id: 25,
    cardTitle: "Winterlude",
    cardSubtitle: "5 Feb 2023",
    cardText:"Winterlude is a one-day event where developers can tech a day off! We plan to help like-minded people connect, interact and learn, by exposing them to all things technology through our amazing speaker lineup and activities.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-winterlude/",
    eventImg: winterlude,
  },

  {
    id: 24,
    cardTitle: "Solution Challenge",
    cardSubtitle: "25 Jan 2023",
    cardText:"Google Solution Challenge is a competition that encourages and recognizes innovative solutions that use Google technology to solve real-world problems. The challenge is open to teams of developers, students, and entrepreneurs.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-solution-challenge-info-session/",
    eventImg: solutionchallenge,
  },
  {
    id: 23,
    cardTitle: "Google Cloud",
    cardSubtitle: "7 Dec 2022 - 8 Dec 2022",
    cardText:"Google Cloud Career Practitioner Program is an opportunity to kickstart your learning on Cloud technology, the tool that powers apps like Google Search, Gmail, and YouTube as well as get hands-on experience on the Google Cloud Platform.",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-google-cloud-career-practitioner-1/",
    eventImg: gccp,
  },

  {
    id: 22,
    cardTitle: "Google Cloud",
    cardSubtitle: "7 Nov 2022",
    cardText:"GDSC-AKGEC is here with the glad tidings of Google Cloud Career Practitioner (GCCP) where you'll be having self-paced labs to learn more about Google cloud services ",
    cardHref:
    "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-google-cloud-career-practitioner/",
    eventImg: gcloud,
  },
  {
    id: 21,
    cardTitle: "Jetpack Compose",
    cardSubtitle: "29 Sept 2022 - 8 Oct 2022",
    cardText:"Here we bring you the second session of the ongoing Compose Camp in collaboration with GDSC Abesec and GDSC Amity Noida, where you would be learning kotlin fundamentals and building the UI.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-info-session-compose-camp/",
    eventImg: jetpack,
  },
  {
    id: 20,
    cardTitle: "Web + Firebase",
    cardSubtitle: "29 May 2022",
    cardText:"GDSC AKGEC invites you to Web+Firebase Hands-on Session in alliance with GDSC Poornima College of Engineering, GDSC Amity University Mumbai, GDSC Indore Institute of Science & Technology, and GDSC, Avantik University",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-web-firebase-hands-on-session/",
    eventImg: webfirebase,
  },
  {
    id: 19,
    cardTitle: "ML Study Jams",
    cardSubtitle: "24 May 2022 - 26 May 2022",
    cardText:"'ML Study Jams', where not only can you learn about the basics of Machine Learning but get a hands-on-experience on Google's own open source library 'TensorFlow'",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-ml-study-jams/",
    eventImg: mlStudy,
  },

  {
    id: 18,
    cardTitle: "Intro to Web",
    cardSubtitle: "16 May 2022",
    cardText:"Join us for Intro to Web with Google Developer Student Clubs, in collaboration with 15 other GDSCs is here with another exhilarating session on Web Development.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-intro-to-web-development/",
    eventImg: introtoweb,
  },
  {
    id: 17,
    cardTitle: "Explore ML",
    cardSubtitle: "15 May 2022",
    cardText:"GDSC is here with another exhilarating session on *Machine Learning*. Machine learning is a subfield of AI, which is broadly defined as the capability of a machine to imitate intelligent human behavior.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-intro-to-machine-learning/",
    eventImg: exploreml,
  },

  {
    id: 16,
    cardTitle: "Flutter Festival",
    cardSubtitle: "15 Mar 2022 - 20 Mar 2022",
    cardText:"GDSC-AKGEC invites you to a series of sessions on Flutter starting from 15th to 20th March. Where you get to learn the fundamentals of Google technologies- featuring Flutter, Firebase and Google Cloud.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-flutter-festival-ajay-kumar-garg-engineering-college-ghaziabad-1/",
    eventImg: flutterfestival,
  },

 
  {
    id: 15,
    cardTitle: "GDSC WOW",
    cardSubtitle: "25 Feb 2022 - 27 Feb 2022",
    cardText:"Google Developer Student Clubs across India, are here with one of the biggest collaborative online events from over 200+ GDSCs across India “GDSC WOW” a.k.a. Wonder of Wonders.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-gdsc-wow-wonder-of-wonders/",
    eventImg: gdscwow,
  },

  {
    id: 14,
    cardTitle: "Flutter Info session",
    cardSubtitle: "23 Feb 2022",
    cardText:"GDSC AKGEC, along with 25 more GDSCs, brings you Flutter Festival : First Glimpse",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-flutter-festival-ajay-kumar-garg-engineering-college-ghaziabad/",
    eventImg: flutter,
  },
  {
    id: 13,
    cardTitle: "Solution Challenge",
    cardSubtitle: "17 Jan 2022",
    cardText:"Solution Challenge is an annual contest presented by Developer Student Clubs (DSC) that invites students to develop solutions for local community problems using one or more Google products or platforms.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-solution-challenge-2022/",
    eventImg: solutionchallenge,
  },
  {
    id: 12,
    cardTitle: "Flutter with DASH",
    cardSubtitle: "4 Dec 2021 - 5 Dec 2021",
    cardText:"We are here with yet another knowledge and fun-filled workshop for all of you, on app development, 'Flutter with Dash'.Here's your chance to build beautiful native apps on iOS and Android from a single code base!.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-flutter-with-dash/",
    eventImg: webEvent,
  },
  {
    id: 11,
    cardTitle: "Cloud Study Jam",
    cardSubtitle: "20 Sept 2021",
    cardText:
      "Google Cloud Platform is a suite of cloud computing services that runs on the same infrastructure that Google uses internally for its end-user products. Join us on 20 September for an introductory session on getting started with 30 days of GCP.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-introduction-session-30-days-of-google-cloud/",
    eventImg: gcp30,
  },
  {
    id: 10,
    cardTitle: "Info Session",
    cardSubtitle: "15 Sept 2021",
    cardText:
      "If you are someone who wants to bring a change, an enthusiast with an undying will and passion just in search of a platform to showcase the best of your skills, Developer Student Clubs-AKGEC, powered by google developers, has something for you.",
    cardHref:
      "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-ghaziabad-presents-gdsc-akgec-introductory-session/",
    eventImg: infosession2,
  },
  {
    id: 9,
    cardTitle: "ML Workshop",
    cardSubtitle: "3 Jul 2021 - 6 Jul 2021",
    cardText:
      "This workshop gives you the gist of python basics, algorithms used in machine learning and how to move forward in this domain. It is a 4-day hourly workshop. A competition planned at the end of the workshop for all the young minds.",
    cardHref:
      "https://dsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-presents-ml-workshop-by-dsc-akgec/",
    eventImg: mlEvent,
  },
  {
    id: 8,
    cardTitle: "Android Study Jams",
    cardSubtitle: "19 Dec - 23 Dec 2020",
    cardText:
      "DSC-AKGEC brought 'Android Study Jams'. A chance for people who are new to android to learn how to build Android apps in Kotlin by following an online curriculum. The workshop was for people who had prior experience in programming. ",
    cardHref:
      "https://dsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-presents-android-study-jams-prior-programming-experience/",
    eventImg: androidStudyJams,
  },
  {
    id: 7,
    cardTitle: "Hackfest 3",
    cardSubtitle: "24 Oct 2020",
    cardText:
      "DSC AKGEC presented a YouTube live Session, with Mr. Govind Goel on Youtube. He is a GSoC'20 mentor at DIAL. The event focused on the 'Basics of Open Source'.The session entailed basics of GitHub and the relevance of open source.",
    cardHref: "https://www.instagram.com/p/CGmu-1gD7y5/",
    eventImg: hackfest3,
  },
  
  {
    id: 6,
    cardTitle: "Hackfest 2",
    cardSubtitle: "4 Oct 2020",
    cardText:
      "An informative and interactive session on Getting Started with Open Source by Mr. Anuj Garg, founder of Code for Cause and Open source Outreach and Advocate at JBoss Community. The session was live on YouTube on 4th of October at 5pm.",
    cardHref: "https://www.instagram.com/p/CF36VjWDOSd/",
    eventImg: hackfest2,
  },

  {
    id: 5,
    cardTitle: "Hackfest 1",
    cardSubtitle: "2 Oct 2020",
    cardText:
      "This session was based on Introduction to Cybersecurity and CFTs by Mr. Sunny Mishra, one of the founding members and CTF player and works as a reverse engineer and exploit developer.",
    cardHref: "https://www.instagram.com/p/CFzu9tsjF7L/",
    eventImg: hackfest1,
  },
  {
    id: 4,
    cardTitle: "GitHub Tutorial",
    cardSubtitle: "30 Sep 2020",
    cardText:
      "GDSC AKGEC brings Hands on live workshop on Github and it's different functions.",
    cardHref: "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-presents-github-tutorial/",
    eventImg: githubtut,
  },
  {
    id: 3,
    cardTitle: "Info Session",
    cardSubtitle: "18 Sept 2020",
    cardText:
      "An Info session was conducted for students of 2nd Year to make them aware about the technologies and working culture at DSC AKGEC and what all we are planning to conduct in this academic year.",
    cardHref: "https://gdsc.community.dev/events/details/developer-student-clubs-ajay-kumar-garg-engineering-college-presents-dsc-demo-session/",
    eventImg: infosession1,
  },
  {
    id: 2,
    cardTitle: "Week Of Learning",
    cardSubtitle: "27 Mar 2020 - 8 Apr 2020",
    cardText:
      "An intensive week- long learning program from Propagate An online learning platform to learn Web Development from absolute basics and build the first project. Amazon vouchers, certificates for completion and goodies were provided.",
    cardHref: "https://www.instagram.com/p/B9_znonD7V5/",
    eventImg: weekOfLearning,
  },
  {
    id: 1,
    cardTitle: "Google Cloud Platform",
    cardSubtitle: "31 Oct 2019",
    cardText:
      "Contained 3 tracks: App and Web Development, Data Science, Machine Learning. Each track had 5 quests and 1 quest to be completed within a week. The eligible entries were evaluated by Google and successful entries received hoodies, badges, pens, stickers and swags.",
    cardHref: "https://www.instagram.com/p/B3OicsfDTRE/",
    eventImg: gcp,
  },
];
