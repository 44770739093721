import React, { Component} from "react";
import { Switch, Route } from "react-router-dom";
// import { CSSTransition, TransitionGroup } from "react-transition-group";

//import css
import classes from "./Layout.module.css";
import classesDark from "./LayoutDark.module.css";

// import component
import Navbar from "../Navigation/Navbar/Navbar";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import Home from "../Home/Home";
import Events from "../Events/Events";
import Contact from "../Contact/Contact";
import Team from "../Team/Team";
import Alumni from "../Team/Alumni/Alumni";
import Projects from "../Projects/Projects";
import Blog from "../Blog/Blog";
import ScrollToTop from "../../ScrollToTop";
import Error404 from "../Error404/Error404";
//import Form from "../EventReg/Form";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import SuccessPage from "../EventReg/Success";
import Form from "../EventReg/Form";

import SuccessPage from "../EventReg/Success";
import CustomizedTimeline from "../EventReg/registerPage";
// import Admin from "../AdminAuth/Admin";
// import Otp from "../OTP/otp";
// import Portal from "../Portal/Portal";
// import Footer from "../UI/Footer/Footer";
// import Close from '../EventRegistration/Close/Close';
// import Attendence from "../EventRegistration/Attendence/Attendence";

class Layout extends Component {
  state = {
    theme: true,
    styles: classes,
    success: JSON.parse(localStorage.getItem("success")),
  };

  constructor(props) {
    super(props);
    this.nodeRef = React.createRef();
    this.themeToggler = this.themeToggler.bind(this);
  }

  componentDidMount() {
    const storedTheme = localStorage.getItem("theme");
  
    if (storedTheme === null) {
  
      const prefersDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  
     
      if (prefersDarkMode) {
        this.setState({ theme: false }); 
        this.setState({ styles: classesDark }); 
      } else {
        this.setState({ theme: true }); 
        this.setState({ styles: classes }); 
      }
  
     
      localStorage.setItem("theme", prefersDarkMode ? "false" : "true");
    } else {
     
      if (storedTheme === "true") {
        this.setState({ theme: true }); 
        this.setState({ styles: classes }); 
      } else {
        this.setState({ theme: false }); 
        this.setState({ styles: classesDark }); 
      }
    }

    window.onbeforeunload = () => {
      localStorage.clear();
    };
  }
  
  themeToggler() {
    this.state.theme === true
      ? this.setState({ theme: false })
      : this.setState({ theme: true });

    if (this.state.theme === true) {
      localStorage.setItem("theme", "false");
    } else {
      localStorage.setItem("theme", "true");
    }

    if (this.state.theme) {
      this.setState({ styles: classesDark });
    } else {
      this.setState({ styles: classes });
    }
  }

  render() {
    return (
      <>
        <div>
          <Navbar switchTheme={this.themeToggler} theme={this.state.theme} />

          <SideDrawer
            switchTheme={this.themeToggler}
            theme={this.state.theme}
          />
          <ScrollToTop />
        </div>
        <div className={this.state.styles.Layout}>
          <Route
            render={({ location }) => (
              // <TransitionGroup component={null}>
              //   <CSSTransition
              //     key={location.key}
              //     timeout={450}
              //     classNames="fade"
              //   >
              <Switch location={location}>
                <Route
                  path="/"
                  exact
                  component={() => <Home theme={this.state.theme} />}
                />
                <Route
                  path="/events"
                  exact
                  component={() => <Events theme={this.state.theme} />}
                />
                <Route
                  path="/projects"
                  exact
                  component={() => <Projects theme={this.state.theme} />}
                />
                <Route
                  path="/blog"
                  exact
                  component={() => <Blog theme={this.state.theme} />}
                />
                <Route
                  path="/team"
                  exact
                  component={() => <Team theme={this.state.theme} />}
                />
                <Route
                  path="/alumni"
                  exact
                  component={() => <Alumni theme={this.state.theme} />}
                />
                {/* <Route
                  path="/submission"
                  exact
                  component={() => <Submission theme={this.state.theme} />}
                />
                <Route
                  path="/recruitment"
                  exact
                  component={() => <Recruitment theme={this.state.theme} />}
                /> */}
                {/* <Route
                  path="/proximity"
                  exact
                  component={() => <Proximity theme={this.state.theme} />}
                /> */}
                {/* <Route exact path="/admin/register">
                  {this.state.success && this.state.success ? (
                    <Redirect to="/admin/portal" />
                  ) : (
                    <Admin />
                  )}
                </Route>
                <Route exact path="/admin/login">
                  {this.state.success && this.state.success ? (
                    <Redirect to="/admin/portal" />
                  ) : (
                    <Admin route="Login" />
                  )}
                </Route> */}
                {/* <Route path="/admin/portal"> */}
                {/* {localStorage.setItem("user", "user")} */}
                {/* {!this.state.user ? (
                        <Redirect to="/admin/login" />
                      ) : (
                        <Portal />
                      )}
                    </Route> */}
                {/* <Route
                  path="/admin/portal"
                  component={() => <Portal theme={this.state.theme} />}
                /> */}

                {/* Event registration */}
                {/* <Route
                  path="/event/Proximity3.0"
                  exact
                  component={() => (
                    <GoogleReCaptchaProvider reCaptchaKey="6LdrgwcoAAAAANtmgrZcz0u5eCvWv392q28MdqI6">
                      <CustomizedTimeline theme={this.state.theme} />
                      </GoogleReCaptchaProvider>
                  )}
                /> */}
                {/* <Route
                  path="/event/register"
                  exact
                  component={() => <GoogleReCaptchaProvider reCaptchaKey="6LfyxUQqAAAAAHVj_R2AUPzhylvrC72S_A5xggMF">
                    <Form theme={this.state.theme} />
                    </GoogleReCaptchaProvider>}
                />
                <Route
                  path="/event/register/success"
                  exact
                  component={() => <SuccessPage theme={this.state.theme} />}
                />  */}
                
                {/* <Route
                  path="/check-in"
                  exact
                  component={() => <Attendence theme={this.state.theme} />}
                /> */}
                {/* <Route
                  path="/otp"
                  component={() => <Otp theme={this.state.theme} />}
                /> */}
                <Route
                  path="/details"
                  exact
                  component={() => <Contact theme={this.state.theme} />}
                />
                <Route
                  component={() => <Error404 theme={this.state.theme} />}
                />
              </Switch>
              //   </CSSTransition>
              // </TransitionGroup>
            )}
          />
        </div>
      </>
    );
  }
}

export default Layout;
