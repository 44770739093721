// import person1 from "../../assets/images/person.jpg";



import Shorya from "../../assets/images/team/Shorya.jpg";
import Tarun from "../../assets/images/team/tarun.jpg";
import Abhishek from "../../assets/images/team/Abhishek.png";
import Ashutosh from "../../assets/images/team/ashutosh.jpg";
import Hammad from "../../assets/images/team/hammad.jpg";
import Kapish from "../../assets/images/team/kapish.png";
import Nandini from "../../assets/images/team/nandini.jpg";
import Priyanshu from "../../assets/images/team/PRIYANSHU.jpg";
import Samaviah from "../../assets/images/team/sam.jpg";
import Tushar from "../../assets/images/team/tushar.jpg";
import Shreyansh from "../../assets/images/team/shreyansh.jpg";
import Vikshita from "../../assets/images/team/vikshita.jpg";
import Yashvi from "../../assets/images/team/yashvi.png";
// import Suvarna from "../../assets/images/team/Suvarna .png";
import Abhishek1 from "../../assets/images/team/Abhishek.jpeg"
import Sachendra from "../../assets/images/team/Sachendra.jpg"
import Nitish from "../../assets/images/team/Nitish.jpg"
import Shreya from "../../assets/images/team/Shreya.jpg"
import Sankalp2 from "../../assets/images/team/Sankalp.jpg"
import Mohit from "../../assets/images/team/Mohit.jpg"
import Ananya from "../../assets/images/team/Ananya.jpeg"
import Vidhi from "../../assets/images/team/Vidhi.jpg"
import Dhruval from "../../assets/images/team/Dhruval.jpg"
import Daiwik from "../../assets/images/team/Daiwik.jpg"
import Aikansh from "../../assets/images/team/Aikansh.jpg"
import Shatakshi from "../../assets/images/team/Shatakshi2.jpeg"
import Ritvik from "../../assets/images/team/Ritvik.jpg"
import Akarsh from "../../assets/images/team/Akarsh.jpeg"
import Namrata from "../../assets/images/team/Namrita.jpg"
import Vishal from "../../assets/images/team/Vishal2.png"

export const TeamData = [
  // {
  //   id: 1,
  //   year: 1,
  //   domain: 8,
  //   cardName: "Ms. Suvarna Mujumdar",
  //   cardDesignation: "Faculty Coordinator",
  //   image: Suvarna,
  //   // links: {
  //   //   github: "/",
  //   //   linkedin: "https://www.linkedin.com/in/suvarna-mujumdar-62aa664b/?originalSubdomain=in",
  //   //   instagram: "/",
  //   //   behance: "/",
  //   //   twitter:"/",
  //   // },
   // },
  

  
  {
    id: 33,
    year: 4,
    domain: 5,
    cardName: "Abhishek Verma",
    cardDesignation: "UI/UX Designing",
    image: Abhishek,
    links: {
      github: "https://github.com/Ayuuxx",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  // {
  //   id: 34,
  //   year: 3,
  //   domain: 2,
  //   cardName: "Ashmit Pandey",
  //   cardDesignation: "Machine Learning",
  //   image: Ashmit,
  //   links: {
  //     github: "https://github.com/Ashm1t",
  //     linkedin: "https://www.linkedin.com/in/ashmit-pandey-308820226/",
  //     instagram: "/",
  //     behance: "/",
  //     twitter:"https://twitter.com/ashmitpandey123",
  //   },
  // },
  {
    id: 35,
    year: 4,
    domain: 4,
    cardName: "Ashutosh Pal",
    cardDesignation: "Managerial",
    image: Ashutosh,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/ashutosh-pal-014179276",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  // {
  //   id: 36,
  //   year: 3,
  //   domain: 1,
  //   cardName: "Avnish Tiwari",
  //   cardDesignation: "Web Development",
  //   image: Avnish,
  //   links: {
  //     github: "https://github.com/avnishkt",
  //     linkedin: "https://www.linkedin.com/in/avnish-kumar-59b628226",
  //     instagram: "/",
  //     behance: "/",
  //     twitter:"https://twitter.com/AvnishT26193873?t=FlA2GoVkEyEvHbsBC2Z-PQ&s=09",
  //   },
  // },
  {
    id: 37,
    year: 4,
    domain: 3,
    cardName: "Hammad Khan",
    cardDesignation: "App Development",
    image: Hammad,
    links: {
      github: "http://github-www.github.com/hammad0110",
      linkedin: "https://www.linkedin.com/in/hammad-khan-860451230",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 38,
    year: 4,
    domain: 3,
    cardName: "Kapish Upadhyay",
    cardDesignation: "App Development",
    image: Kapish,
    links: {
      github: "https://github.com/kapishupadhyay22",
      linkedin: "https://www.linkedin.com/in/kapish-upadhyay-9297b9223",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/UpadhyayKapish",
    },
  },
  {
    id: 39,
    year: 4,
    domain: 1,
    cardName: "Nandini Sharma",
    cardDesignation: "Web Development",
    image: Nandini,
    links: {
      github: "https://github.com/nandini27ns",
      linkedin: "https://www.linkedin.com/in/nandini-sharma-a12b83244/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/nandini27ns",
    },
  },
  // {
  //   id: 40,
  //   year: 3,
  //   domain: 4,
  //   cardName: "Navya Sangwan",
  //   cardDesignation: "Managerial",
  //   image: Navya,
  //   links: {
  //     github: "/",
  //     linkedin: "https://www.linkedin.com/in/navya-sangwan-44b5a5254/",
  //     instagram: "/",
  //     behance: "/",
  //     twitter:"https://twitter.com/sangwan_navya",
  //   },
  // },
  {
    id: 41,
    year: 4,
    domain: 2,
    cardName: "Priyanshu Mishra",
    cardDesignation: "Machine Learning",
    image: Priyanshu,
    links: {
      github: "https://github.com/priyanshu2112032",
      linkedin: "https://www.linkedin.com/in/priyanshu-mishra-149a10205",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/PPmishrAA",
    },
  },
  {
    id: 42,
    year: 4,
    domain: 1,
    cardName: "Samaviah Waize",
    cardDesignation: "Web Development",
    image: Samaviah,
    links: {
      github: "https://github.com/SamaviahWaize",
      linkedin: "https://www.linkedin.com/in/samaviah-waize-746bb5252/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/Samaviah_wz",
    },
  },
  {
    id: 43,
    year: 4,
    domain: 1,
    cardName: "Shorya",
    cardDesignation: "Web Development",
    image: Shorya,
    links: {
      github: "https://github.com/Shorya7",
      linkedin: "https://www.linkedin.com/in/shorya-9b8b50241/",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/ShoryaS7",
    },
  },
  {
    id: 44,
    year: 4,
    domain: 1,
    cardName: "Shreyansh Sachan",
    cardDesignation: "Web Development",
    image: Shreyansh,
    links: {
      github: "https://github.com/ShreYansHSachan11",
      linkedin: "https://www.linkedin.com/mwlite/in/shreyanshsachan11",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 45,
    year: 4,
    domain: 1,
    cardName: "Tarun Lalwani",
    cardDesignation: "Web Development",
    image: Tarun,
    links: {
      github: "https://github.com/tarunl2002",
      linkedin: "https://www.linkedin.com/in/tarun-lalwani-8b8783232",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/tarunl2002",
    },
  },
  {
    id: 46,
    year: 4,
    domain: 5,
    cardName: "Tushar Gupta",
    cardDesignation: "UI/UX Designing",
    image: Tushar,
    links: {
      github: "https://github.com/GadgetVision",
      linkedin: "https://www.linkedin.com/in/tusharguptagv",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/GadgetVision",
    },
  },
  {
    id: 47,
    year: 4,
    domain: 4,
    cardName: "Vikshita Jain",
    cardDesignation: "Managerial",
    image: Vikshita,
    links: {
      github: "https://github.com/VikshitaJain",
      linkedin: "https://www.linkedin.com/in/vikshita-jain-a0ab9b225",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/Vikshita_j?t=8yhgpHAMsQDyZ9dhgzwNfQ&s=09",
    },
  },
  {
    id: 48,
    year: 4,
    domain: 4,
    cardName: "Yashvi Aggarwal",
    cardDesignation: "Managerial",
    image: Yashvi,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/yashvi-agarwal-621a08248",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  
  {
    id: 49,
    year: 3,
    domain: 4,
    cardName: "Abhishek Kumar",
    cardDesignation: "Managerial",
    image: Abhishek1,
    links: {
      github: "https://github.com/abhishek-8081",
      linkedin: "https://www.linkedin.com/in/abhishek-kumar-170492258/",
      instagram: "https://www.instagram.com/_abhishekrajput.in/?hl=en",
      behance: "/",
      twitter:"https://twitter.com/Abhishek_Ji_10",
    },
  },
  {
    id: 50,
    year: 3,
    domain: 3,
    cardName: "Aikansh Tiwari",
    cardDesignation: "App Development",
    image: Aikansh,
    links: {
      github: "https://github.com/aikansh008",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 51,
    year: 3,
    domain: 3,
    cardName: "Akarsh Sahlot",
    cardDesignation: "App Development",
    image: Akarsh,
    links: {
      github: "https://github.com/AkarshSahlot",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 52,
    year: 3,
    domain: 1,
    cardName: "Ananya Jain",
    cardDesignation: "Web Development",
    image: Ananya,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/ananya-jain-6b4795217",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 53,
    year: 3,
    domain: 3,
    cardName: "Daiwik Saxena",
    cardDesignation: "App Development",
    image: Daiwik,
    links: {
      github: "https://github.com/Daiwik_1901",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 54,
    year: 3,
    domain: 5,
    cardName: "Dhruval Gupta",
    cardDesignation: "UI/UX Designing",
    image: Dhruval,
    links: {
      github: "https://github.com/Dig092",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 55,
    year: 3,
    domain: 1,
    cardName: "Mohit Singh",
    cardDesignation: "Web Development",
    image: Mohit,
    links: {
      github: "https://github.com/mohitxroxx",
      linkedin: "https://www.linkedin.com/in/mohitxroxx",
      instagram: "https://www.instagram.com/mohitxroxx?hl=en",
      behance: "/",
      twitter:"https://twitter.com/MOHITxROXX",
    },
  },
  {
    id: 56,
    year: 3,
    domain: 3,
    cardName: "Namrata Yadav",
    cardDesignation: "App Development",
    image: Namrata,
    links: {
      github: "https://github.com/Namrata_yadav_08",
      linkedin: "",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 57,
    year: 3,
    domain: 5,
    cardName: "Nitish Kumar",
    cardDesignation: "UI/UX Designing",
    image: Nitish,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/nitishkumar14/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 58,
    year: 3,
    domain: 2,
    cardName: "Ritvik Sharma",
    cardDesignation: "Machine Learning ",
    image: Ritvik,
    links: {
      github: "/",
      linkedin: "https://www.linkedin.com/in/iamritvik",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 59,
    year: 3,
    domain: 1,
    cardName: "Sachendra Gangwar",
    cardDesignation: "Web Development",
    image: Sachendra,
    links: {
      github: "https://github.com/Sachendra79",
      linkedin: "https://www.linkedin.com/in/sachendra-gangwar-49904b296/",
      instagram: "https://www.instagram.com/sachendra_79/?hl=en",
      behance: "/",
      twitter:"https://twitter.com/Sachendra79",
    },
  },

  
  {
    id: 60,
    year: 3,
    domain: 4,
    cardName: "Sankalp Jha",
    cardDesignation: "Managerial",
    image: Sankalp2,
    links: {
      github: "https://github.com/blackdragoon26",
      linkedin: "https://www.linkedin.com/in/sankalp-jha-18a95a244",
      instagram: "/",
      behance: "/",
      twitter:"https://twitter.com/SankalpJha26",
    },
  },

  {
    id: 61,
    year: 3,
    domain: 5,
    cardName: "Shatakshi",
    cardDesignation: "UI/UX Designing",
    image: Shatakshi,
    links: {
      github: "https://github.com/Shatakshi615",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  {
    id: 62,
    year: 0,
    domain: 5,
    cardName: "Shreya Pal",
    cardDesignation: "Lead (2024)",
    image: Shreya,
    links: {
      github: "https://github.com/Shreyapal18",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },

  {
    id: 63,
    year: 3,
    domain: 3,
    cardName: "Vidhi Gupta",
    cardDesignation: "App Development",
    image: Vidhi,
    links: {
      github: "https://github.com/VIDHIGUPTA17",
      linkedin: "https://www.linkedin.com/in/vidhi-gupta-777299266/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  
  {
    id: 64,
    year: 3,
    domain: 1,
    cardName: "Vishal Malyan",
    cardDesignation: "Web Development",
    image: Vishal,
    links: {
      github: "https://github.com/Vishal2005025",
      linkedin: "/",
      instagram: "/",
      behance: "/",
      twitter:"/",
    },
  },
  
];
