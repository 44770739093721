// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider,  signOut } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyA19hV_rpbRsRpSGuouGQ32fl7X2_gOSrY",
  authDomain: "recruitment-24.firebaseapp.com",
  projectId: "recruitment-24",
  storageBucket: "recruitment-24.appspot.com",
  messagingSenderId: "9326146371",
  appId: "1:9326146371:web:a0e4eb35f15891eaf5f5f6",
  measurementId: "G-9JXDC0CSPX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  hd: "akgec.ac.in",
});

export const logOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      console.log(error);
    });
};
